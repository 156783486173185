import React from "react";

export default function Index({ children, isHandleOnClick, name, id, style={},className="" }) {
  function handleOnClick() {
    if (isHandleOnClick) {
      isHandleOnClick();
    }
  }

  console.log('==handleOnClick==', { isHandleOnClick, name, id, style }, "children", children);

  return (
    <>
      <button
        name={name || ""}
        id={id || ""}
        style={style||{}}
        className={className||""}
        onClick={handleOnClick}
      >
        {children}
      </button>
    </>
  );
}
